.multi-uploadable-field {
  &__input-wrap {
    position: relative;
  }

  input[type='file'] {
    position: absolute;
    z-index: 5;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
  }

  label {
    position: relative;
    z-index: 10;
    cursor: pointer;
  }
}

@include respond-up('large') {
  .multi-uploadable-field {

  }
}

@include respond-up('medium') {
  .multi-uploadable-field {

  }
}

@include respond('medium') {
  .multi-uploadable-field {

  }
}

@include respond-down('medium') {
  .multi-uploadable-field {

  }
}

@include respond-down('small') {
  .multi-uploadable-field {

  }
}