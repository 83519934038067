%text-main {
  font-family: var(--font);
  font-size: var(--font-size);
  font-style: normal;
  font-weight: 400;
  color: var(--primary-color);
  font-stretch: 110%;
  font-variation-settings: var(--font-settings);
  line-height: 110%;
}

@include respond-up("s-large") {
  %text-main {
    --font-size: clamp(0px, calc((60 / 1920) * 100vw), 60px);
  }
}

@include respond("medium") {
  %text-main {
    --font-size: calc((35 / 834) * 100vw);
  }
}

@include respond-down("small") {
  %text-main {
    --font-size: 23px;
  }
}