.social-icons {
  border-radius: 50%;
  border: 1px solid black;
  display: inline-block;
  text-align: center;

  &_yt {
    margin-left: 10px;
  }
}

@include respond-up('s-large') {
  .social-icons {
    overflow: hidden;

    &__wrap,
    &__wrap_vertical {
      display: inline-block;
    }

    &:hover {
      .social-icons {
        &__icon {
          transform: translateY(0);
        }
      }
    }

    &__icon {
      transition: var(--default-transition);
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      transform: translateY(-42px);

      &_clone {
        margin-top: 30px;
      }
    }

    &_vk {
      .social-icons {
        &__icon {
          transform: translateY(-42px);

          &_clone {
            margin-top: 30px;
          }
        }
      }
    }

    &_yt {
      .social-icons {
        &__icon {
          transform: translateY(-47px);

          &_clone {
            margin-top: 30px;
          }
        }
      }
    }
  }
}

@include respond-up('medium') {
  .social-icons {
    width: 47px;
    height: 47px;
    padding-top: 16px;
  }
}

@include respond('medium') {
  .social-icons {
    &__icon {
      &_clone {
        display: none;
      }
    }
  }
}

@include respond-down('small') {
  .social-icons {
    width: 43px;
    height: 43px;
    padding-top: 13px;

    &__wrap {
      &_vertical {
        display: flex;
        flex-direction: column;

        .social-icons {
          &_yt {
            margin-left: 0;
            margin-top: 10px;
          }
        }
      }
    }

    &__icon {
      &_clone {
        display: none;
      }
    }
  }
}
