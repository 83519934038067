.error-page {
  display: flex;
  flex-direction: column;
  align-items: center;
}

@include respond-up('s-large') {
  .error-page {
    padding-top: 100px;
    margin-top: 170px;
    padding-bottom: 150px;
  }
}

@include respond-up('medium') {
  .error-page {
    &__description {
      margin-top: 15px;
    }

    &__button {
      margin-top: 40px;
    }
  }
}

@include respond('medium') {
  .error-page {
    padding-top: 80px;
    margin-top: 200px;
    padding-bottom: 150px;
  }
}

@include respond-down('small') {
  .error-page {
    padding-top: 54px;
    margin-top: 125px;
    padding-bottom: 100px;

    &__button {
      margin-top: 30px;
    }
  }
}