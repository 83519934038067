.rslider-test {
  &__images-slider {

  }

  &__images-slide {
    height: 100px;
  }

  &__texts-slider {

  }

  &__text {

  }
}

