.modal-form {

  &__policy-link {
    border-bottom: 1px solid var(--primary-color-opacity-40);
  }

  &__policy {
    color: var(--primary-color-opacity-40);
  }

  &__flat-info {
    background: var(--background-color);
    padding: 10px;
    display: flex;
    align-items: center;
    width: 100%;
  }

  &__flat-text-wrap {
    display: flex;
    flex-direction: column;
  }

  &__image-wrap {
    background: white;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__picture {
    height: 100%;
  }

  &__image {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}

@include respond-up('s-large') {
  .modal-form {

    &__field {
      &:not(:first-child) {
        margin-top: 55px;
      }
    }

    &__subtitle {
      margin-top: 40px;
      width: var(--grid-column2);
    }

    &__fields-wrap {
      margin-top: 80px;
    }

    &__flat-info {
      margin-top: 40px;
    }
  }
}

@include respond-up('medium') {
  .modal-form {

    &__bottom {
      display: flex;
      align-items: center;
    }

    &__policy {
      margin-left: 45px;
    }

    &__bottom {
      margin-top: 40px;
    }

    &__flat-detail {
      margin-top: 10px;
    }

    &__flat-text-wrap {
      margin-left: 30px;
    }

    &__image-wrap {
      width: 100px;
      height: 100px;
    }
  }
}

@include respond('medium') {
  .modal-form {
    &__field {
      &:not(:first-child) {
        margin-top: 45px;
      }
    }

    &__subtitle {
      margin-top: 30px;
      width: var(--grid-column4);
    }

    &__fields-wrap {
      margin-top: 70px;
    }

    &__flat-info {
      margin-top: 30px;
    }
  }
}

@include respond-down('small') {
  .modal-form {
    &__field {
      &:not(:first-child) {
        margin-top: 30px;
      }
    }

    &__subtitle {
      margin-top: 20px;
    }

    &__fields-wrap {
      margin-top: 40px;
    }

    &__bottom {
      margin-top: 20px;
    }

    &__policy {
      margin-top: 20px;
    }

    &__flat-info {
      margin-top: 14px;
    }

    &__flat-text-wrap {
      margin-left: var(--grid-gap);
    }

    &__image-wrap {
      width: 63px;
      height: 63px;
    }
  }
}