// Тема - как выглядят те или иные элементы
.genplan {
  position: relative;

  --preloader-bg-color: #eee;
  --preloader-color: #000;

  [data-gen-loader] {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    z-index: 2000;
    background-color: var(--preloader-bg-color);
    transition: 0.3s;
    opacity: 0;
    visibility: hidden;

    &:before {
      content: 'Загрузка';
      color: var(--preloader-color);
      position: absolute;
      z-index: 100;
      top: 50%;
      left: 50%;
      transform: translate3d(-50%, -50%, 0);
      font-size: 12px;
      transition: 0.8s;
      pointer-events: none;
    }

    &:after {
      position: absolute;
      content: '';
      width: 100px;
      height: 100px;
      border-radius: 1000px;
      border: 3px solid var(--preloader-color);
      border-bottom: none;
      border-top: none;
      top: calc(50% - 50px);
      left: calc(50% - 50px);
      z-index: 110;
      animation-duration: 1s;
      animation-name: gen-loader-animation;
      animation-iteration-count: infinite;
      animation-timing-function: linear;
      transition: 0.8s;
      pointer-events: none;
    }

    &._opened {
      opacity: 1;
      visibility: visible;
    }
  }

  &__minimap {
    height: 50px;
    position: absolute;
    bottom: 30px;
    left: 30px;
    border: 2px solid #fff;
    border-radius: 2px;
    z-index: 1000;
    padding: 4px;
  }

  &__minimap-bar {

  }

  &__minimap-handle {
    border-radius: 2px;
    background-color: #fff;
  }

  // Позиционирование балуна на мобильнике
  .genplan__object-balloon._modal {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate3d(-50%, -50%, 0);
    z-index: 1000;
  }

  // Балун на десктопе
  &__object-balloon._popover {
    background-color: #fff;
    padding: 10px;
    transition: all 0.3s;

    // Отступ балуна от точки привязки
    --balloon-offset: 10px;
    // Цвет "Ножки балуна"
    --balloon-pin-color: #fff;

    &.to-right {
      margin-left: var(--balloon-offset);

      &:before {
        left: -8px;
        border-width: 8px 8px 8px 0;
        border-color: transparent var(--balloon-pin-color) transparent transparent;
      }
    }

    &.to-left {
      margin-right: var(--balloon-offset);

      &:before {
        right: -8px;
        border-width: 8px 0 8px 8px;
        border-color: transparent transparent transparent var(--balloon-pin-color);
      }
    }

    &.to-top:before {
      bottom: var(--pin-y-offset);
    }

    &.to-bottom:before {
      top: var(--pin-y-offset);
    }

    &:before {
      position: absolute;
      content: '';
      width: 0;
      height: 0;
      border-style: solid;
    }
  }

  &__scene-modals {
    // Черная подложка над картой на мобильнике, когда открыт балун
    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      pointer-events: none;
      z-index: 10;
      opacity: 0;
      background-color: rgba(0, 0, 0, 0.6);
      transition: all 0.4s;
    }

    &._opened:before {
      opacity: 1;
    }
  }

  // Переходы и прозрачности для полигонов
  &__layer-substrate,
  &__layer-polygon {
    svg {
      path {
        transition: all 0.4s;
        opacity: 0.2;

        &._hover {
          opacity: 0.5;
        }
      }
    }
  }

  &__scene._scrollable {
    .genplan {
      &__layer-substrate,
      &__layer-polygon {
        svg {
          path {
            transition: all 0.4s;
            opacity: 0.5;

            &._hover {
              opacity: 0.5;
            }
          }
        }
      }
    }
  }

  &__scenes {
    transform: translate3d(0,0,0);
  }

  &__scene {
    &._show {
      animation-name: gen-scene-show;
      animation-fill-mode: forwards;
      animation-duration: 0.7s;
    }

    &._hide {
      animation-name: gen-scene-hide;
      animation-fill-mode: forwards;
      animation-duration: 0.5s;
    }
  }
}

@keyframes gen-scene-hide {
  from {
    opacity: 1;
    visibility: visible;
    transform: scale(1);
  }

  to {
    opacity: 0;
    visibility: hidden;
    transform: scale(1.2);
  }
}

@keyframes gen-scene-show {
  from {
    opacity: 0;
    visibility: hidden;
    transform: scale(0.9);
  }

  to {
    opacity: 1;
    visibility: visible;
    transform: scale(1);
  }
}

@keyframes gen-loader-animation {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(180deg);
  }
}