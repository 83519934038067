.modal-form-success {
  &__title {
    font-weight: 400;
    line-height: 110%;
    color: #000000;
    font-stretch: 110%;
    font-variation-settings: var(--font-settings);
  }

  &__subtitle {
    font-weight: 400;
    line-height: 140%;
    color: #000000;
    font-stretch: 110%;
    font-variation-settings: var(--font-settings);
  }
}

@include respond-up('large') {
  .modal-form-success {
    &__title {
      font-size: 30px;
      margin-top: 40px;
    }

    &__subtitle {
      margin-top: 40px;
    }
  }
}

@include respond-up('medium') {
  .modal-form-success {
    &__subtitle {
      font-size: 18px;
      max-width: var(--grid-column3);
    }

    &__icon {
      height: 112px;
    }
  }
}

@include respond('medium') {
  .modal-form-success {
    &__title {
      font-size: 25px;
      margin-top: 30px;
    }

    &__subtitle {
      margin-top: 30px;
    }
  }
}

@include respond-down('small') {
  .modal-form-success {
    &__title {
      font-size: 20px;
      margin-top: 20px;
    }

    &__subtitle {
      margin-top: 20px;
      font-size: 16px;
    }

    &__icon {
      height: 82px;

      svg {
        width: 82px;
        height: 82px;
      }
    }
  }
}