.tabs {
  //overflow-x: scroll;
  //margin: 0 calc(var(--grid-spacer) * -1);

  &::-webkit-scrollbar {
    display: none;
  }

  &__text {
    white-space: nowrap;
  }

  &__list {
    display: flex;
  }

  &__first {
    //Табы 1
    .tabs {
    }
  }
}