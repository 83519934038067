:root {
  --modal-bg: rgba(0, 0, 0, 0.3);
  --modal-content-radius: 0px;
  --modal-content-shadow: none;
  --modal-content-padding: 30px;
  --modal-content-background: #fff;
  --modal-closer-color: white;
  --modal-closer-size: 30px;
  --modal-preloader-size: 30px;
}

.modal {
  &__bg {
    position: fixed;
    height: 100%;
    width: 100%;
    z-index: 10000;
    top: 0;
    left: 0;
    background: var(--t);
    transition: background .4s var(--teaser-transition-func);

    &._opened {
      background: var(--modal-bg);
    }

    &._closed {
      background: var(--t);
    }
  }

  &__layout {
    position: relative;
    z-index: 11000;
    cursor: default;
    margin: 0 auto;
    min-height: 100%;
    display: flex;
    transition-delay: 0s;
    transition: transform .4s var(--teaser-transition-func);
  }

  &__container {
    border-radius: var(--modal-content-radius);
    box-shadow: var(--modal-content-shadow);
    position: relative;
    display: flex;
    align-items: center;
    background: var(--modal-content-background);

    &._opened {
    }

    &._closed {
    }
  }

  &__content {
    overflow: scroll;
    display: flex;

    img {
      max-width: 100%;
    }
  }

  &__closer {
    position: absolute;
    pointer-events: none;
    color: #000000;
    text-decoration: none;
    cursor: pointer;

    &:before {
      content: '';
      height: 2px;
      background: #fff;
      position: absolute;
      top: 0;
      transform-origin: top left;
      transform: rotate(45deg);
    }

    &:after {
      content: '';
      height: 2px;
      background: #fff;
      position: absolute;
      bottom: 0;
      transform-origin: bottom left;
      transform: rotate(-45deg);
    }
  }
}

body.modal-loading {
  overflow: hidden;
  //--preloader-size: 100px;

  //пульсирующий кружочек раньше был
  &__loader {
  }

  &__content {
    overflow: scroll;
    height: 100%;
    width: 100%;
  }
}

@include respond-up('s-large') {
  .modal {
    &__container {
      width: calc(50vw + var(--grid-gap) / 2);
      height: calc(var(--vh, 1vh) * 100);
    }

    &__content {
      padding: var(--grid-gap);
      width: calc(50vw + var(--grid-gap) / 2);
      height: calc(var(--vh, 1vh) * 100);
    }

    &__layout {
      justify-content: flex-end;

      &._opened {
      }

      &._closed {
      }
    }

    &__closer {
      top: calc(var(--vh, 1vh) * 50);
      transform: translateY(-50%);
      left: calc(-83px - var(--grid-gap));
    }
  }
}

@include respond('s-large') {
  .modal {
  }
}

@include respond-up('medium') {
  .modal {
    &__closer {
      width: 83px;
      height: 83px;

      &:before {
        width: 113px;
      }

      &:after {
        width: 113px;
      }
    }
  }
}

@include respond('medium') {
  .modal {
    &__container {
      width: 100vw;
      height: calc(var(--vh, 1vh) * 100 - 163px);
    }

    &__closer {
      top: calc(-83px - var(--grid-gap));
    }
  }
}

@include respond-down('medium') {
  .modal {
    &__layout {
      &._opened {
      }

      &._closed {
      }
    }

    &__content {
      padding: var(--grid-spacer);
      width: 100%;
      height: 100%;
    }

    &__container {
      min-height: 50vh;
      align-self: flex-end;
    }

    &__closer {
      left: 50%;
      transform: translateX(-50%);
    }
  }
}

@include respond-down('small') {
  .modal {
    &__container {
      width: 100vw;
      height: calc(var(--vh, 1vh) * 100 - 52px);
    }

    &__closer {
      width: 24px;
      height: 24px;
      top: calc(-24px - var(--grid-gap));

      &:before {
        width: 32px;
      }

      &:after {
        width: 32px;
      }
    }
  }
}