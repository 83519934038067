.button {
  padding: 0;
  margin: 0;
  text-align: center;
  transition: var(--default-transition);
  border: 1px solid black;
  cursor: pointer;
  text-decoration: none;
  border-radius: 100px;
  display: inline-block;
  white-space: nowrap;
}