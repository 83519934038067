.header {
  //position: fixed;
  width: 100%;
  z-index: 4700;
  background: white;

  &__wrap {
    height: 100px;
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__logo {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
    height: 100%;
    display: flex;
    align-items: center;
  }
}